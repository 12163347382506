import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Styles from "./footer.module.css"
import { GiLoveHowl } from 'react-icons/gi'

const footer = () => {
  return (
    <Container fluid className={Styles.footer}>
      <Row>
        <Col md={3}>
          <h4>Über uns</h4>
          <em>Digitales Design & Beratung. Für erfolgreiche Unternehmungen.</em>
        </Col>
        <Col md={3}>
          <h4>Services</h4>
          Beratung & Strategie
          <br />
          Marketing
          <br />
          Webdesign & Fotografie
          <br />
        </Col>
        <Col md={3}>
          <h4>Kontakt</h4>
          hallo@inkfs.com
          <br />
          <AniLink fade to="/impressum">
            Impressum
          </AniLink>
          <br />
          <AniLink fade to="/links">
            Links
          </AniLink>
          <br />
          <AniLink fade to="/datenschutz">
            Datenschutz
          </AniLink>
        </Col>
        <Col md={3}>
          <h4>Adresse</h4>
          Ink and Feather Design Studios
          <br />
          Lipper Weg 121
          <br />
          45772 Marl
          <br />
          Deutschland
        </Col>
      </Row>
      <p class={Styles.info}>
        Ink and Feather Design Studios<br />Mit <GiLoveHowl /> gemacht in 2021.
      </p>
    </Container>
  )
}

export default footer
