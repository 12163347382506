import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/inkfs_logo_50.png"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#fff`,
      marginBottom: `0`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div className="text-center" style={{ margin: `0` }}>
        <AniLink
          cover
          to="/"
          direction="down"
          bg="
          url(https://www.inkandfeather.de/ink-logo-white.webp)
          center / cover   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */          
          "
          style={{
            textTransform: `uppercase`,
            textDecoration: `none`,
            lineHeight: `2.5rem`,
          }}
          className="h2 inkblue"
        >
          <img src={logo} alt="Ink and Feather Design Studios Logo" />
          <span style={{ display: `none` }}>INK</span> AND FEATHER DESIGN
          STUDIOS
        </AniLink>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
